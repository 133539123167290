import React, { useState, useEffect } from 'react';
import BanderaEs from '../../Imagenes/BanderaEs.jpg';
import BanderaCa from '../../Imagenes/BanderaCa.jpg';
import BanderaIn from '../../Imagenes/BanderaIn.jpg';
import BanderaPa from '../../Imagenes/BanderaPa.png';
import BanderaAr from '../../Imagenes/Bandera_ar.png';
import i18next from "i18next";
import "../../styles/GlobalComponents.css";
import { isPlatform } from '@ionic/react';

function HeaderComponent() {
    const [ios, setIos] = useState(true);

    useEffect(() => {
        if (isPlatform('ios')) {
            setIos(false);
        } else {
            setIos(true);
        }
    }, [])

    return (
        <div className="container h-100">
            <div className="container h-100">
                <div className="">
                    <button type="button" className="banderas">
                        <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                            src={BanderaEs}
                            alt="Bandera-es" onClick={() => i18next.changeLanguage('es')} /></button>
                    {process.env.REACT_APP_IDIOMA == 'ca' || process.env.REACT_APP_IDIOMA_SECUNDARIO == 'ca' ?
                        <button type="button" className="banderas">
                            <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                                src={BanderaCa}
                                alt="BanderaCa" onClick={() => i18next.changeLanguage('ca')} /></button> : ''}

                    {process.env.REACT_APP_IDIOMA == 'in' || process.env.REACT_APP_IDIOMA_SECUNDARIO == 'in' ?
                        <button type="button" className="banderas">
                            <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                                src={BanderaIn}
                                alt="BanderaIn" onClick={() => i18next.changeLanguage('in')} />
                        </button>
                        : ''}
                    {process.env.REACT_APP_IDIOMA == 'pa' || process.env.REACT_APP_IDIOMA_SECUNDARIO == 'pa' ?
                        <button type="button" className="banderas">
                            <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                                src={BanderaPa}
                                alt="BanderaPa" onClick={() => i18next.changeLanguage('pa')} /></button> : ''}
                    {process.env.REACT_APP_IDIOMA == 'ar' || process.env.REACT_APP_IDIOMA_SECUNDARIO == 'ar' ?
                        <button type="button" className="banderas">
                            <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                                src={BanderaAr}
                                alt="BanderaAr" onClick={() => i18next.changeLanguage('ar')} /></button> : ''}
                </div>
            </div>
        </div>
    );

};

export default HeaderComponent;