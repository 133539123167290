import React from "react";
import "../../styles/GlobalComponents.css";
import Moment from 'react-moment';
import { withTranslation } from "react-i18next";


class ContratoTranslated extends React.Component {
  render() {
    const { t } = this.props;
    if (this.props.telefono == '') {
      return (
        <div className="container-component">
          <div className="dettalles-contrato">
            <div className="detalles-contra">
              <span className="documento">{t('Contrato.numero',)}</span>
              <span>{this.props.id}</span>
              <br></br>
              <span className="documento">{t('Contrato.fecha',)}  </span>
              <span><Moment format="DD/MM/YYYY ">{this.props.fecha}</Moment></span>
            </div>
            <div className="descripcion-contrato">
              <br></br>
              <span className="documento ">{t('Contrato.info',)}</span>
              <br></br>
            </div>
            <div className="descripcion-texto">
              <span className="descripcion-texto">{this.props.titulo} </span>
            </div>
            <br></br>
            <div className="precio-tarifa">
              <span className="documento">{t('Contrato.precio',)}</span>
              <span> {this.props.precio != '' && this.props.precio != 0 ? this.props.precio : this.props.precioOri} €</span>
            </div>
          </div>
        </div>
      );

    }
    return (
      <div className="container-component">
        <div className="dettalles-contrato">
          <div className="detalles-contra">
            <span className="documento">{t('Contrato.numero',)}</span>
            <span>{this.props.id}</span>
            <br></br>
            <span className="documento">{t('Contrato.fecha',)}  </span>
            <span><Moment format="DD/MM/YYYY ">{this.props.fecha}</Moment></span>
          </div>
          <div className="descripcion-contrato">
            <br></br>
            <span className="documento ">{t('Contrato.info',)}</span>
            <br></br>
          </div>
          <div className="descripcion-texto">
            <span className="descripcion-texto">{this.props.titulo} </span>
          </div>
          <br></br>
          <div className="telefono">
            <span className="documento">{t('Contrato.telefono',)}  </span>
            <span>{this.props.telefono}</span>
          </div>
          <div className="precio-tarifa">
            <span className="documento">{t('Contrato.precio',)}</span>
            <span> {this.props.precio != '' && this.props.precio != 0 ? this.props.precio : this.props.precioOri} €</span>
          </div>
        </div>
      </div>
    );
  }
}
const Contrato = withTranslation('common')(ContratoTranslated)
export default Contrato;