import api from "../servicios/api";
import Alertas from '../servicios/Alertas';

async function getDatosEmpresa(lengua) {
    let respuesta;
    let appConfig;
    let funcionalidades;
    try {
        await api.Clientes.datosEmpresa().then(
            ({ data }) => (respuesta = data)
        ).catch(function (err) {
            console.log(err);
        });

        await api.Clientes.configApp().then(
            ({ data }) => (appConfig = data)
        ).catch(function (err) {
            console.log(err);
        });
        //console.log(appConfig[0].mostrar_puk)
        if (!Array.isArray(respuesta) || !Array.isArray(appConfig)) {
            Alertas.sinConexion(lengua);
            return false;
        } else {
            funcionalidades = JSON.parse(appConfig[0].configuracion_funcionalidades);
            let empresa = {
                "nombre": respuesta[0].nombre,
                "nombreComercial": respuesta[0].empresa,
                "email": respuesta[0].email,
                "accesoBaja": respuesta[0].bloquear_acceso_baja,
                "telefono": respuesta[0].telefono1,
                "obligar_cambiar_pass": respuesta[0].cambiar_pass,
                "requerir_lopd": respuesta[0].aceptar_lopd,
                "texto_lopd": respuesta[0].texto_lopd,

            };
            let appConf = {
                "acerca_de": appConfig[0].acerca_de,
                "asunto_ticket": appConfig[0].asunto_interesa_ticket,
                "agrupar_tarifas": appConfig[0].agrupar_tarifas_operador,
                "mostrar_ticket": appConfig[0].mostrar_ticket_interesa_tarifa,
                "mensaje": appConfig[0].mensaje_interesa_ticket,
                "config_inicio": appConfig[0].configuracion_inicio_app,
                "recuperar_clave": appConfig[0].recuperar_clave,
                "imagen_tarifa": appConfig[0].imagen_en_tarifas,
                "mostrar_puk": appConfig[0].mostrar_puk,
                "grafico_consumo": appConfig[0].grafico_consumo,
                "activacion_servicios": appConfig[0].mostrar_servicios_telefonia

            }

            localStorage.setItem("empresa", JSON.stringify(empresa));
            localStorage.setItem("appconf", JSON.stringify(appConf));
            localStorage.setItem("funciones", JSON.stringify(funcionalidades));
            return true;
        }

    } catch (error) {

    }
}



const DatosEmpresa = {
    obtener(lengua) {
        return getDatosEmpresa(lengua);
    },

};


export default DatosEmpresa;
