import React from "react";
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Ok from '../../Imagenes/ok.png';
import Error from '../../Imagenes/error.png';
import Pageinfo from '../PantallaCarga/Pageinfo';
import InputSelect from '../Elementos/InputSelect';
import InputText from '../Elementos/InputText';
import InputCheckbox from "../Elementos/InputCheckbox";
import Alert from "../Elementos/Alert";

let activa = '';
let estRo = '';
let array = [
    { value: "1", texto: "Ocultar" },
    { value: "2", texto: "Mostrar" },
];

let datos = [];
class ServicioAireTranslated extends React.Component {
    constructor(props) {
        super(props)
        this.actualizarState = this.actualizarState.bind(this);
        this.state = {
            loading: true,
            error: false,
            telefono: 'Seleccione un número',
            form: {
                Boic: false,
                CountryFixedOGCallsBarred: false,
                CountryMobileOGCallsBarred: false,
                AireBarrado118: false,
                AireBarrado118_N2: false,
                AireBarrado118_N3: false,
                AireBarrado901: false,
                AireBarrado902: false,
                AireBarrado905: false,
                AireBarrado907: false,
                AireBarradoPremium: false,
                AireBarradoPremiumPlus: false,
                AireBarrado70X: false,
                AireBarrado_m2m: false,
                CW: false,
                Hold: false,
                ShortMessageMTPP: false,
                ShortMessageMOPP: false,
                Clip: false,
                Clir: false,
                MultiPTY: false,
                Lca: false,
                PS: false,
                Telephony: false,
                Cfu: false,
                CfuextString: '',
                Cfb: false,
                CfbextString: '',
                Cfnrc: false,
                CfnrcextString: '',
                Cfnry: false,
                CfnryextString: '',
                VLR: false,
                LastUpdateLocation: false,
                LTE: false,
            },
            verServicios: false,
            alertaOk: false,
            alertaKo: false,


        };
    }

    componentDidMount() {
        this.consultaServicios(this.props.ddi);
    }

    consultaServicios = async (ddi) => {
        this.setState({ loading: true, error: null });
        estRo = '';
        if (ddi != '') {
            try {
                await api.Clientes.consultarServicios(ddi).then(
                    ({ data }) => (estRo = data),
                );
                if (estRo.respuesta == "OK") {
                    if (Array.isArray(estRo.datos)) {
                        datos = estRo.datos;
                        datos.map(linea => {
                            const { featureID, active, servicio, extString, extNumber } = linea;
                            this.setState({
                                form: {
                                    ...this.state.form,
                                    [featureID]: active,
                                },
                            });
                            this.setState({
                                form: {
                                    ...this.state.form,
                                    [featureID + extString]: extString,
                                },
                            });
                        });
                        this.setState({ verServicios: true });
                    } else {
                        this.setState({ verServicios: false });
                    }
                }
                this.setState({ loading: false, error: null });
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }

    actualizarState(input) {
        console.log(input);
        this.setState({
            ...this.state,
            [input.name]: input.value,
        }, () => { });
        console.log(this.state)
    }


    activarServicios = async (e) => {
        e.preventDefault();
        console.log(this.state)
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.modificarServicios(JSON.stringify(this.state.form)).then(
                ({ data }) => (activa = data),
            );
            this.setState({ loading: false, error: null });
            if (activa == 'OK') {
                this.setState({ alertaOk: true });
            } else {
                this.setState({ alertaKo: true });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: null, sinDatos: true });
        }
    }

    handleChange = async (e) => {
        this.setState({ telefono: e.target.value });
        this.consultaServicios(e.target.value);
    };

    render() {
        const { t } = this.props;

        const renderizarFila = (resultado) => {
            const { featureID, active, servicio, extString, extNumber } = resultado;

            if (featureID !== 'RoamingOutsidePLMN-CountryBarred' && featureID !== 'RoamerAccessToHPLMN-AP-Barred' && servicio) {
                return (
                    <div key={featureID}>
                        <div className="servicio-nombre" >{servicio}</div>
                        <div className='div-ser-aire'>
                            <InputCheckbox
                                name={servicio}
                                valor={active}
                                actualizarState={this.actualizarState} />
                        </div>
                        {featureID === 'Clir' && (
                            <>
                                <InputSelect
                                    name={`extNumber[${featureID}]`}
                                    //label="Buzón de voz"
                                    actualizarState={this.actualizarState}
                                    opciones={array}
                                    default={1} />
                            </>
                        )}

                        {extString != '' && featureID != 'LTE' && featureID != 'VLR' && (
                            <div>
                                <InputText
                                    name={featureID + 'extString'}
                                    placeholder="Teléfono"
                                    valor={extString}
                                    mensajeError="Correo no valido"
                                    actualizarState={this.actualizarState} />
                            </div>
                        )}
                        <hr className='azul' />
                    </div>
                );
            } else if ((featureID === 'RoamingOutsidePLMN-CountryBarred' && servicio) || (featureID === 'RoamerAccessToHPLMN-AP-Barred' && servicio)) {
                return (
                    <></>
                );
            }
        };

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <Pageinfo datos={'sinRoaming'} />;
        }

        return <div className=''>
            {this.state.verServicios &&
                <div>
                    {/* Aquí iteramos sobre los datos y renderizamos cada fila */}
                    {datos.map((resultado) => renderizarFila(resultado))}
                    <div>
                        <button className="btn-servicios" onClick={this.activarServicios}>
                            <span className='tarifa-detalles-text'>{t('Servicios.cambiar',)}  </span>
                            <FontAwesomeIcon icon="circle-check" className="icon-white" />
                        </button>

                    </div>
                </div>
            }
            {this.state.alertaOk ?
                <Alert
                    header={t('ConsumoReal.roamingOk',)}
                    imagen={Ok}
                    text={t('Aceptar.acep',)}
                    estado={this.state.alertaOk}
                    clase={'correcto'}
                    accion={'/Herramientas'}
                /> : ''}

            {this.state.alertaKo ?
                <Alert
                    header={t('ConsumoReal.roamingko',)}
                    imagen={Error}
                    text={t('Aceptar.acep',)}
                    estado={this.state.alertaKo}
                    clase={'error'}
                    accion={'/Herramientas'}
                /> : ''}
        </div >
    }

}

const ServicioAire = withTranslation('common')(ServicioAireTranslated)
export default ServicioAire;