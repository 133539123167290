
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import Pageinfo from '../PantallaCarga/Pageinfo';
import ServicioCablemovil from '../serviciosApi/servicioCablemovil';
import ServicioAire from '../serviciosApi/ServicioAire';

let userConsumo = '';
let api_tipo = 0;
class ServiciosTranslated extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: false,
            apiTipo: 0,
            verServicios: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.telefonos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });

            if (!Array.isArray(userConsumo)) {
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    consultaServicios = async (ddi) => {
        this.setState({ loading: true, error: null, verServicios: false });
        if (ddi != '') {
            try {

                await api.Clientes.saberApiTipo(ddi).then(
                    ({ data }) => (api_tipo = data),
                );

                if (Number.isInteger(api_tipo)) {
                    if (api_tipo == 1 || api_tipo == 2) {
                        this.setState({ loading: false, apiTipo: api_tipo, verServicios: true });
                        console.log(this.state.apiTipo)
                    }
                } else {
                    this.setState({ loading: false, error: null });
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }


    handleChange = async (e) => {
        this.setState({ telefono: e.target.value });
        this.consultaServicios(e.target.value);
    };

    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <Pageinfo datos={'sinRoaming'} />;
        }

        return <div className=''>
            <select name="telefono" onChange={this.handleChange} className="select-tlf" value={this.state.telefono}>
                <option key="0" value="" >{t('Consumo.numero',)}</option>
                {this.state.datos.map((dato) =>
                    <option key={dato.id} value={dato}>{dato}</option>
                )}
            </select>
            <hr></hr>
            {this.state.verServicios &&
                <>
                    {this.state.apiTipo == 1 ? <ServicioAire ddi={this.state.telefono} /> : ''}
                    {this.state.apiTipo == 2 ? <ServicioCablemovil ddi={this.state.telefono} /> : ''}
                </>

            }
        </div >
    }

}

const Servicios = withTranslation('common')(ServiciosTranslated)
export default Servicios;