import React, { useEffect } from 'react';
import api from '../servicios/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalComponents.css";
import { IonLabel } from '@ionic/react';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';




let userData;
const Lopd = (props) => {

    const [lopd, setLopd] = React.useState(false);
    const [nolopd, setNolopd] = React.useState(false);
    const [publicidad, setPublicidad] = React.useState(false);
    const [nopublicidad, setNopublicidad] = React.useState(false);
    const [empresa, setEmpresa] = React.useState('');
    const [alerta, setAlerta] = React.useState(false);


    useEffect(() => {
        const datos_empresa = JSON.parse(localStorage.getItem('empresa'));
        setEmpresa(datos_empresa.nombre)
    }, []);

    const handleChange = (event) => {
        if (event.target.name == 'lopd') {
            setLopd(event.target.checked)
            if (nolopd === true) {
                setNolopd(false);
            }
        } else if (event.target.name == 'nolopd') {
            setNolopd(event.target.checked)
            if (lopd === true) {
                setLopd(false);
            }
        } else if (event.target.name == 'publicidad') {
            setPublicidad(event.target.checked)
            if (nopublicidad === true) {
                setNopublicidad(false);
            }
        } else if (event.target.name == 'nopublicidad') {
            setNopublicidad(event.target.checked)
            if (publicidad === true) {
                setPublicidad(false);
            }
        }


    };
    const recuperaInput = async () => {

        if (publicidad === false && nopublicidad === false) {
            setAlerta(true)
        } else if (lopd === false && nolopd === false) {
            setAlerta(true)
        } else {
            setAlerta(false)
            let publi = 1;
            let lopdisp = 1;
            if (publicidad === false) {
                publi = 0;
            }

            if (lopd === false) {
                lopdisp = 2;
            }

            await api.Clientes.guardarLopd(publi, lopdisp).then(
                ({ data }) => (userData = data)
            ).catch(function (err) {
                console.log(err);
            });
            const cliente = JSON.parse(localStorage.getItem('user'));
            let usuario = {
                "id": cliente.id,
                "usuario": cliente.clienteid,
                "codigo": cliente.codigo,
                "password": cliente.password,
                "cif": cliente.cif,
                "nombre": cliente.nombre,
                "apellidos": cliente.apellidos,
                "email": cliente.email,
                "telefono": cliente.telefono1,
                "movil": cliente.movil,
                "fechaFin": cliente.fecha_fin,
                "nombre_completo": cliente.nombrecompleto,
                "personaFisica": cliente.personafisica,
                "sms": cliente.movil_sms,
                "esUsuario": cliente.esusuario,
                "pass_cambiada": cliente.cambio_pass_primer_acceso,
                "lopd": lopdisp
            };
            localStorage.removeItem('user');
            localStorage.setItem("user", JSON.stringify(usuario));
            window.location = '/Home'
        }
    }

    return (
        <div>
            <div className="formulario-lopd">
                <span>De conformidad con lo establecido en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27
                    de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales
                    y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos).
                    Por ello, nos ponemos en contacto con usted solicitándole las respectivas autorizaciones e informándole de sus derechos, de manera que cumplamos
                    la respectiva normativa.
                    <br></br> En nombre de la empresa {empresa} tratamos la información que nos facilita con el fin de enviarle publicidad
                    relacionada con nuestros productos y servicios por cualquier medio (postal, email o teléfono) e invitarles a eventos organizados por la empresa.
                    Los datos proporcionados se conservarán mientras no solicite el cese de la actividad. Los datos no se cederán a terceros salvo en los casos en que
                    exista una obligación legal. Usted tiene derecho a obtener confirmación sobre si en {empresa}. estamos tratando sus datos personales,
                    por tanto, tiene derecho a acceder a sus datos personales, rectificar los datos inexactos o solicitar su supresión cuando los mismos ya no sean
                    necesarios para los fines que fueron recogidos.</span>
            </div>
            <IonLabel className="label" >Consiento que {empresa}. realice acciones comerciales y me ofrezca productos y servicios tanto generales
                como personalizados por cualquier medio, incluidos los telemáticos.</IonLabel>
            <div className="">
                <input
                    onChange={handleChange}
                    className=""
                    type="checkbox"
                    name="publicidad"
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={publicidad}
                />
                <IonLabel className="label" > Acepto</IonLabel>
                <input
                    onChange={handleChange}
                    className=""
                    type="checkbox"
                    name="nopublicidad"
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={nopublicidad}
                />
                <IonLabel className="label" > NO Acepto</IonLabel>
            </div>
            <br></br>
            <IonLabel className="label" >Consiento que comunique mis datos personales a empresas del Grupo {empresa}, así como a empresas
                colaboradoras y/o participadas por el Grupo  {empresa} para ofrecerme sus productos y servicios, tanto de manera general como personalizada,
                por cualquier medio, incluidos los telemáticos.</IonLabel>
            <div className="">
                <input
                    onChange={handleChange}
                    className=""
                    type="checkbox"
                    name="lopd"
                    checked={lopd}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <IonLabel className="label" > Acepto</IonLabel>
                <input
                    onChange={handleChange}
                    className=""
                    type="checkbox"
                    name="nolopd"
                    checked={nolopd}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <IonLabel className="label" > NO Acepto</IonLabel>
            </div>
            <div className="botones-linea botonespass">
                <button type="button" onClick={recuperaInput} className="btn-lopd">
                    <FontAwesomeIcon icon="circle-check" className="icon-btn" /> Aceptar
                </button>
            </div>
            <IonAlert
                isOpen={alerta}
                cssClass='error'
                header='Debe Indicar si acepta o no'
                message={`<img src="${Error}" alt="error" className="errorimg">`}
                buttons={[{
                    text: [`Aceptar`],
                    handler: () => {
                        setAlerta(false);
                    }
                }]}
            />
        </div>
    );
}

export default Lopd;