import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import Alert from '../Elementos/Alert';
import Ok from '../../Imagenes/ok.png';
import Error from '../../Imagenes/error.png';
import PageError from '../PantallaCarga/PageError';
import PageLoading from "../PantallaCarga/PageLoading";

let activa;
class RoamingGenericoTranslated extends React.Component {
    constructor(props) {
        super(props)
        this.actualizarState = this.actualizarState.bind(this);
        this.state = {
            loading: false,
            error: false,
            modal: false,
            estadoRoaming: props.estado,
            alertaOk: false,
            alertaKo: false
        }
    }

    activarServicios = () => {
        this.setState({ modal: true });
    }


    realizarCambiosRoaming = async (resultado) => {
        this.setState({ modal: false });
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.activarRoaming(this.props.ddi, JSON.stringify(resultado)).then(
                ({ data }) => (activa = data),
            );
            this.setState({ loading: false, error: null });
            if (activa == 'OK') {
                this.setState({ alertaOk: true });
            } else {
                this.setState({ alertaKo: true });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: null, sinDatos: true });
        }
    }

    cerrarModal = () => {
        window.location.reload();
    }

    actualizarState(input) {
        this.setState({
            ...this.state,
            [input.name]: input.value,
        }, () => { });
    }


    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }
        return (
            <div className='activar-roaming'>

                {this.state.estadoRoaming ? <div className='float-roaming'>
                    <div className="switch-button-roaming">
                        <input
                            type="checkbox"
                            name="switchLabel"
                            id="switchLabel"
                            onClick={() => this.realizarCambiosRoaming(2)}
                            className="switch-button__checkbox-roaming" />
                        <label htmlFor="switchLabel" className="switch-button__label-roaming" />
                    </div>
                    <div> Puedes desactivar el roaming pulsando en el botón</div>
                </div>
                    : <div className='float-roaming'>
                        <span>Estado del servicio</span>
                        <div className="switch-button-roaming-apa">
                            <input
                                type="checkbox"
                                name="switchLabel"
                                id="switchLabel"
                                onClick={() => this.activarServicios()}
                                className="switch-button__checkbox-roaming-apa" />
                            <label htmlFor="switchLabel" className="switch-button__label-roaming-apa" />
                        </div>
                    </div>
                }

                <Modal isOpen={this.state.modal} className="roaming-modal">
                    <ModalHeader className="modal-header-roaming">
                        <span style={{
                            fontWeight: 800,
                        }}>{t('ConsumoReal.info5',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.cerrarModal}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="modal-body-roaming">
                        <p>{t('ConsumoReal.info1',)}</p>
                        <p>{t('ConsumoReal.info2',)}</p>
                        <p>{t('ConsumoReal.info3',)}</p>
                        <p>{t('ConsumoReal.info4',)}</p>

                    </ModalBody>
                    <ModalFooter className='modal-footer-roaming'>
                        <button className="btn-rojo-xl-tarifas" onClick={() => this.realizarCambiosRoaming(2)}>
                            <span>{t('ConsumoReal.activar',)}</span>
                        </button>
                    </ModalFooter>
                </Modal>
                {this.state.alertaOk ?
                    <Alert
                        header={t('ConsumoReal.roamingOk',)}
                        imagen={Ok}
                        text={t('Aceptar.acep',)}
                        estado={this.state.alertaOk}
                        clase={'correcto'}
                        accion={'/Herramientas'}
                    /> : ''}

                {this.state.alertaKo ?
                    <Alert
                        header={t('ConsumoReal.roamingko',)}
                        imagen={Error}
                        text={t('Aceptar.acep',)}
                        estado={this.state.alertaKo}
                        clase={'error'}
                        accion={'/Herramientas'}
                    /> : ''}
            </div >

        )
    }
}

const RoamingGenerico = withTranslation('common')(RoamingGenericoTranslated)
export default RoamingGenerico;

