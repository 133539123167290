import React from "react";
import InputSelect from '../Elementos/InputSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import Alert from '../Elementos/Alert';
import Ok from '../../Imagenes/ok.png';
import Error from '../../Imagenes/error.png';
import PageError from '../PantallaCarga/PageError';
import PageLoading from "../PantallaCarga/PageLoading";

const valores = [
    { value: "1", texto: "Activo zona 1" },
    { value: "0", texto: "Desactivado" },
    { value: "2", texto: "Activo zona 1, 2 y 3" },
];

let activa;
class RoamingAireTranslated extends React.Component {
    constructor(props) {
        super(props)
        this.actualizarState = this.actualizarState.bind(this);
        this.state = {
            modal: false,
            datos: props.datos,
            llamadas: props.llamadas,
            alertaOk: false,
            alertaKo: false,
            loading: false,
            error: false,
        }
    }

    activarServicios = () => {
        this.setState({ modal: true });
    }


    realizarCambiosRoaming = async () => {
        this.setState({ modal: false });
        const valorAire = { llama: this.state.llamadas, datos: this.state.datos };
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.activarRoaming(this.props.ddi, JSON.stringify(valorAire)).then(
                ({ data }) => (activa = data),
            );
            this.setState({ loading: false, error: null });
            if (activa == 'OK') {
                this.setState({ alertaOk: true });
            } else {
                this.setState({ alertaKo: true });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: null, sinDatos: true });
        }
    }

    cerrarModal = () => {
        window.location.reload();
    }

    actualizarState(input) {
        this.setState({
            ...this.state,
            [input.name]: input.value,
        }, () => { });
    }


    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }
        return (
            <div className='activar-roaming'>
                <InputSelect
                    name="datos"
                    label="Datos"
                    actualizarState={this.actualizarState}
                    opciones={valores}
                    default={this.props.datos} />

                <InputSelect
                    name="llamadas"
                    label="LLamadas"
                    actualizarState={this.actualizarState}
                    opciones={valores}
                    default={this.props.llamadas} />

                <div style={{ marginTop: '15px' }}>
                    <button className="btn-servicios" onClick={this.activarServicios} >
                        <span className='tarifa-detalles-text'> {t('Servicios.cambiar',)}  </span>
                        <FontAwesomeIcon icon="circle-check" className="icon-white" />
                    </button>
                </div>
                <Modal isOpen={this.state.modal} className="roaming-modal">
                    <ModalHeader className="modal-header-roaming">
                        <span style={{
                            fontWeight: 800,
                        }}>{t('ConsumoReal.info5',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.cerrarModal}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="modal-body-roaming">
                        <p>{t('ConsumoReal.info1',)}</p>
                        <p>{t('ConsumoReal.info2',)}</p>
                        <p>{t('ConsumoReal.info3',)}</p>
                        <p>{t('ConsumoReal.info4',)}</p>

                    </ModalBody>
                    <ModalFooter className='modal-footer-roaming'>
                        <button className="btn-rojo-xl-tarifas" onClick={() => this.realizarCambiosRoaming()}>
                            <span>{t('ConsumoReal.activar',)}</span>
                        </button>
                    </ModalFooter>
                </Modal>
                {this.state.alertaOk ?
                    <Alert
                        header={t('ConsumoReal.roamingOk',)}
                        imagen={Ok}
                        text={t('Aceptar.acep',)}
                        estado={this.state.alertaOk}
                        clase={'correcto'}
                        accion={'/Herramientas'}
                    /> : ''}

                {this.state.alertaKo ?
                    <Alert
                        header={t('ConsumoReal.roamingko',)}
                        imagen={Error}
                        text={t('Aceptar.acep',)}
                        estado={this.state.alertaKo}
                        clase={'error'}
                        accion={'/Herramientas'}
                    /> : ''}
            </div >

        )
    }
}

const RoamingAire = withTranslation('common')(RoamingAireTranslated)
export default RoamingAire;

