import React from 'react';

class InputText extends React.Component {
    constructor(props) {
        super(props);
        this.actualizarState = this.actualizarState.bind(this);
        this.state = {
            value: "",
            error: false,
            mensajeError: ""
        };
    }
    /*actualizarState(e) {
        const { name, value } = e.target;
        //console.log(name)
        //console.log(value)
        console.log(this.props.validacion(value));

        if (this.props.validacion(value)) {
            this.setState({
                value,
                error: false,
                mensajeError: ""
            });
            this.props.actualizarState({
                name, value, error: false
            });
        } else {
            this.setState({
                value,
                error: true,
                mensajeError: this.props.mensajeError
            });
            this.props.actualizarState({
                name, value, error: true
            });
        }
    }*/
    actualizarState(e) {
        const { name, value } = e.target;
        this.setState({ value });
        this.props.actualizarState({ name, value })
    }
    render() {
        return (
            <div className="componente-input">
                {/*<label htmlFor={"id-" + this.props.name}>{this.props.label}</label>*/}
                <input
                    id={"id-" + this.props.name}
                    type="text"
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    className="select-servicios"
                    onChange={this.actualizarState}
                    defaultValue={this.props.valor}
                    /*value={this.props.valor}*/ />
                {/*
                    this.state.error ? (
                        <p className="componente-input-error">{this.state.mensajeError}</p>
                    ) : ("")
                */}
            </div>
        )
    }
}


export default InputText;