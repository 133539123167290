import React from 'react';

class InputCheckbox extends React.Component {
    constructor(props) {
        super(props)
        this.actualizarState = this.actualizarState.bind(this);
        this.state = { activo: "" };
    }

    actualizarState(e) {
        const { name, value } = e.target;
        this.setState({ value });
        this.props.actualizarState({ name, value })
    }
    render() {
        return (
            <div className="componente-checkbox">
                {/*<label htmlFor={"id-" + this.props.name}>{this.props.label}</label>*/}
                <input
                    id={"id-" + this.props.name}
                    type="checkbox"
                    name={this.props.name}
                    //className="select-servicios"
                    onChange={this.actualizarState}
                    defaultChecked={this.props.valor}
                    /*value={this.props.valor}*/ />
                {/*
                    this.state.error ? (
                        <p className="componente-input-error">{this.state.mensajeError}</p>
                    ) : ("")
                */}
            </div>
        )
    }
}
export default InputCheckbox;