
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Ok from '../../Imagenes/ok.png';
import Error from '../../Imagenes/error.png';
import Pageinfo from '../PantallaCarga/Pageinfo';
import InputSelect from '../Elementos/InputSelect';
import InputText from '../Elementos/InputText';
import Alert from '../Elementos/Alert';

let activa = '';
let userConsumo = '';
let estRo = '';
let array = [
    { value: "enabled", texto: "Activo" },
    { value: "disabled", texto: "Desactivado" },
];

let arrayInternacional = [
    { value: "enabled", texto: "Nacionales e internacionales habilitadas" },
    { value: "disabled", texto: "Desactivado" },
    { value: "international-calls-disabled", texto: "Desactivado internacionales" },
];
let api_tipo = 0;
class ServicioCablemovilTranslated extends React.Component {
    constructor(props) {
        super(props)
        this.actualizarState = this.actualizarState.bind(this);
        this.state = {
            loading: true,
            error: false,
            verServicios: false,
            valuesNor: array,
            valuesInter: arrayInternacional,
            inboundCalls: 'disabled',
            outboundCalls: 'disabled',
            outboundPremiumCalls: 'disabled',
            inboundSMSs: 'disabled',
            outboundSMSs: 'disabled',
            data: 'disabled',
            unconditional: 'disabled',
            unconditionalddi: '',
            busy: 'disabled',
            busyddi: '',
            nonReachable: 'disabled',
            nonReachableddi: '',
            nonReply: 'disabled',
            nonReplyddi: '',
            telefono: 'Seleccione un número',
            voiceMail: 'disabled',
            alertaOk: false,
            alertaKo: false,

        };
    }

    componentDidMount() {
        this.consultaServicios(this.props.ddi);
    }

    consultaServicios = async (ddi) => {
        this.setState({ loading: true, error: null });
        estRo = '';
        if (ddi != '') {
            try {
                await api.Clientes.consultarServicios(ddi).then(
                    ({ data }) => (estRo = data),
                );

                if (estRo != 0) {
                    if (Array.isArray(estRo.lServicios)) {
                        estRo.lServicios.map(servicio => {
                            if (servicio.atributo == 'Llamadas entrantes') {
                                if (servicio.value == 'Activo') {
                                    this.setState({ inboundCalls: 'enabled' });
                                } else {
                                    this.setState({ inboundCalls: 'disabled' });
                                }
                            }
                            if (servicio.atributo == 'Llamadas salientes') {
                                if (servicio.value == 'Nacionales e internacionales habilitadas') {
                                    this.setState({ outboundCalls: 'enabled' });
                                } else if (servicio.value == 'Internacionales deshabilitadas') {
                                    this.setState({ outboundCalls: 'international-calls-disabled' });
                                } else {
                                    this.setState({ outboundCalls: 'disabled' });
                                }
                            }
                            if (servicio.atributo == 'Servicios premium') {
                                if (servicio.value == 'Activo') {
                                    this.setState({ outboundPremiumCalls: 'enabled' });
                                } else {
                                    this.setState({ outboundPremiumCalls: 'disabled' });
                                }
                            }
                            if (servicio.atributo == 'SMSs Entrantes') {
                                if (servicio.value == 'Activo') {
                                    this.setState({ inboundSMSs: 'enabled' });
                                } else {
                                    this.setState({ inboundSMSs: 'disabled' });
                                }
                            }
                            if (servicio.atributo == 'SMSs Salientes') {
                                if (servicio.value == 'Activo') {
                                    this.setState({ outboundSMSs: 'enabled' });
                                } else {
                                    this.setState({ outboundSMSs: 'disabled' });
                                }
                            }
                            if (servicio.atributo == 'Datos') {
                                if (servicio.value == 'Activo') {
                                    this.setState({ data: 'enabled' });
                                } else {
                                    this.setState({ data: 'disabled' });
                                }
                            }
                        })

                    }

                    if (typeof (estRo.buzonVozActivo) && estRo.buzonVozActivo == true) {
                        this.setState({ voiceMail: 'enabled' });
                    } else {
                        this.setState({ voiceMail: 'disabled' });
                    }

                    if (Array.isArray(estRo.lDesvios)) {
                        estRo.lDesvios.map(desvio => {
                            if (desvio.atributo == 'Desvío si comunica') {
                                if (desvio.value == 'Activo') {
                                    this.setState({ busy: 'enabled' });
                                } else {
                                    this.setState({ busy: 'disabled' });
                                }
                                if (desvio.msisdn != '') {
                                    this.setState({ busyddi: desvio.msisdn });
                                }
                            }
                            if (desvio.atributo == 'Desvío si no hay cobertura') {
                                if (desvio.value == 'Activo') {
                                    this.setState({ nonReachable: 'enabled' });
                                } else {
                                    this.setState({ nonReachable: 'disabled' });
                                }
                                if (desvio.msisdn != '') {
                                    this.setState({ nonReachableddi: desvio.msisdn });
                                }
                            }
                            if (desvio.atributo == 'Desvío si no contesta') {
                                if (desvio.value == 'Activo') {
                                    this.setState({ nonReply: 'enabled' });
                                } else {
                                    this.setState({ nonReply: 'disabled' });
                                }
                                if (desvio.msisdn != '') {
                                    this.setState({ nonReplyddi: desvio.msisdn });
                                }
                            }
                            if (desvio.atributo == 'Desvío incondicional') {
                                if (desvio.value == 'Activo') {
                                    this.setState({ unconditional: 'enabled' });
                                } else {
                                    this.setState({ unconditional: 'disabled' });
                                }
                                if (desvio.msisdn != '') {
                                    this.setState({ unconditionalddi: desvio.msisdn });
                                }
                            }

                        })
                    }
                    this.setState({ loading: false, error: null, verServicios: true });

                } else {
                    this.setState({ loading: false, error: null, verServicios: false });
                }

            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }

    actualizarState(input) {
        this.setState({
            ...this.state,
            [input.name]: input.value,
        }, () => { /*console.log(this.state);*/ });
    }


    activarServicios = async (e) => {
        e.preventDefault();
        console.log(this.state)
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.modificarServicios(JSON.stringify(this.state)).then(
                ({ data }) => (activa = data),
            );
            this.setState({ loading: false, error: null });
            if (activa == 'OK') {
                this.setState({ alertaOk: true });
            } else {
                this.setState({ alertaKo: true });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: null, sinDatos: true });
        }
    }

    handleChange = async (e) => {
        this.setState({ telefono: e.target.value });
        this.consultaServicios(e.target.value);
    };

    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <Pageinfo datos={'sinRoaming'} />;
        }

        return <div className=''>
            <select name="telefono" onChange={this.handleChange} className="select-tlf" value={this.state.telefono}>
                <option key="0" value="" >{t('Consumo.numero',)}</option>
                {this.state.datos.map((dato) =>
                    <option key={dato.id} value={dato}>{dato}</option>
                )}
            </select>
            <hr></hr>
            {this.state.verServicios &&

                < div className='servicios_cable'>
                    <div className='div-servicios'>
                        <InputSelect
                            name="inboundCalls"
                            label="Llamadas entrantes"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.inboundCalls} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="outboundCalls"
                            label="Llamadas salientes"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesInter}
                            default={this.state.outboundCalls} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="outboundPremiumCalls"
                            label="Servicios premiun"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.outboundPremiumCalls} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="inboundSMSs"
                            label="SMSs Entrantes"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.inboundSMSs} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="outboundSMSs"
                            label="SMSs Salientes"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.outboundSMSs} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="data"
                            label="Datos"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.data} />
                    </div>
                    <hr></hr>
                    <div className='div-servicios'>
                        <InputSelect
                            name="unconditional"
                            label="Desvío incondicional"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.unconditional} />
                        <InputText
                            name="unconditional-ddi"
                            placeholder="Teléfono"
                            valor={this.state.unconditionalddi}
                            mensajeError="Correo no valido"
                            actualizarState={this.actualizarState} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="busy"
                            label="Desvío si comunica"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.busy} />
                        <InputText
                            name="busy-ddi"
                            placeholder="Teléfono"
                            valor={this.state.busyddi}
                            mensajeError="Correo no valido"
                            actualizarState={this.actualizarState} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="nonReachable"
                            label="Desvío si no hay cobertura"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.nonReachable} />
                        <InputText
                            name="nonReachable-ddi"
                            placeholder="Teléfono"
                            valor={this.state.nonReachableddi}
                            mensajeError="Correo no valido"
                            actualizarState={this.actualizarState} />
                    </div>
                    <div className='div-servicios'>
                        <InputSelect
                            name="nonReply"
                            label="Desvío si no contesta"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.nonReply} />
                        <InputText
                            name="nonReply-ddi"
                            placeholder="Teléfono"
                            valor={this.state.nonReplyddi}
                            mensajeError="Correo no valido"
                            actualizarState={this.actualizarState} />
                    </div>
                    <hr />
                    <div className='div-servicios'>
                        <InputSelect
                            name="voiceMail"
                            label="Buzón de voz"
                            actualizarState={this.actualizarState}
                            opciones={this.state.valuesNor}
                            default={this.state.voiceMail} />
                    </div>
                    <div>
                        <button className="btn-servicios" onClick={this.activarServicios}>
                            <span className='tarifa-detalles-text'>{t('Servicios.cambiar',)}  </span>
                            <FontAwesomeIcon icon="circle-check" className="icon-white" />
                        </button>

                    </div>
                </div>

            }
            {this.state.alertaOk ?
                <Alert
                    header={t('ConsumoReal.roamingOk',)}
                    imagen={Ok}
                    text={t('Aceptar.acep',)}
                    estado={this.state.alertaOk}
                    clase={'correcto'}
                    accion={'/Herramientas'}
                /> : ''}

            {this.state.alertaKo ?
                <Alert
                    header={t('ConsumoReal.roamingko',)}
                    imagen={Error}
                    text={t('Aceptar.acep',)}
                    estado={this.state.alertaKo}
                    clase={'error'}
                    accion={'/Herramientas'}
                /> : ''}
        </div >
    }

}

const ServicioCablemovil = withTranslation('common')(ServicioCablemovilTranslated)
export default ServicioCablemovil;