import React from 'react';

class InputSelect extends React.Component {
    constructor(props) {
        super(props)
        this.actualizarState = this.actualizarState.bind(this);
        this.state = { activo: "" };
    }

    actualizarState(e) {
        const { name, value } = e.target;
        this.setState({ value });
        this.props.actualizarState({ name, value })
    }
    render() {
        return (
            <> <label className='etiqueta-servicios' htmlFor={"id-" + this.props.name}>{this.props.label}</label>
                <select
                    id={"id-" + this.props.name}
                    name={this.props.name}
                    className="select-servicios"
                    onChange={this.actualizarState}
                    defaultValue={this.props.default}>
                    {
                        this.props.opciones.map((opcion, index) => (
                            <option hey={index} value={opcion.value}>{opcion.texto}</option>
                        ))
                    }
                </select>
            </>
        )
    }
}
export default InputSelect;