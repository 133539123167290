import { func } from "prop-types";

function htmlEntities(str) {
    const patron = /<br \/>/g;
    const li = /<\/li>/g;
    const p = /<\/p>/g;
    return String(str).replace(/&ntilde;/g, 'ñ')
        .replace(/&Ntilde;/g, 'Ñ')
        .replace(/&amp;/g, '&')
        .replace(/&Ntilde;/g, 'Ñ')
        .replace(/&ntilde;/g, 'ñ')
        .replace(/&Ntilde;/g, 'Ñ')
        .replace(/&Agrave;/g, 'À')
        .replace(/&Aacute;/g, 'Á')
        .replace(/&Acirc;/g, 'Â')
        .replace(/&Atilde;/g, 'Ã')
        .replace(/&Auml;/g, 'Ä')
        .replace(/&Aring;/g, 'Å')
        .replace(/&AElig;/g, 'Æ')
        .replace(/&Ccedil;/g, 'Ç')
        .replace(/&Egrave;/g, 'È')
        .replace(/&Eacute;/g, 'É')
        .replace(/&Ecirc;/g, 'Ê')
        .replace(/&Euml;/g, 'Ë')
        .replace(/&Igrave;/g, 'Ì')
        .replace(/&Iacute;/g, 'Í')
        .replace(/&Icirc;/g, 'Î')
        .replace(/&Iuml;/g, 'Ï')
        .replace(/&ETH;/g, 'Ð')
        .replace(/&Ntilde;/g, 'Ñ')
        .replace(/&Ograve;/g, 'Ò')
        .replace(/&Oacute;/g, 'Ó')
        .replace(/&Ocirc;/g, 'Ô')
        .replace(/&Otilde;/g, 'Õ')
        .replace(/&Ouml;/g, 'Ö')
        .replace(/&Oslash;/g, 'Ø')
        .replace(/&Ugrave;/g, 'Ù')
        .replace(/&Uacute;/g, 'Ú')
        .replace(/&Ucirc;/g, 'Û')
        .replace(/&Uuml;/g, 'Ü')
        .replace(/&Yacute;/g, 'Ý')
        .replace(/&THORN;/g, 'Þ')
        .replace(/&szlig;/g, 'ß')
        .replace(/&agrave;/g, 'à')
        .replace(/&aacute;/g, 'á')
        .replace(/&acirc;/g, 'â')
        .replace(/&atilde;/g, 'ã')
        .replace(/&auml;/g, 'ä')
        .replace(/&aring;/g, 'å')
        .replace(/&aelig;/g, 'æ')
        .replace(/&ccedil;/g, 'ç')
        .replace(/&egrave;/g, 'è')
        .replace(/&eacute;/g, 'é')
        .replace(/&ecirc;/g, 'ê')
        .replace(/&euml;/g, 'ë')
        .replace(/&igrave;/g, 'ì')
        .replace(/&iacute;/g, 'í')
        .replace(/&icirc;/g, 'î')
        .replace(/&iuml;/g, 'ï')
        .replace(/&eth;/g, 'ð')
        .replace(/&ntilde;/g, 'ñ')
        .replace(/&ograve;/g, 'ò')
        .replace(/&oacute;/g, 'ó')
        .replace(/&ocirc;/g, 'ô')
        .replace(/&otilde;/g, 'õ')
        .replace(/&ouml;/g, 'ö')
        .replace(/&oslash;/g, 'ø')
        .replace(/&ugrave;/g, 'ù')
        .replace(/&uacute;/g, 'ú')
        .replace(/&ucirc;/g, 'û')
        .replace(/&uuml;/g, 'ü')
        .replace(/&yacute;/g, 'ý')
        .replace(/&thorn;/g, 'þ')
        .replace(/&nbsp/g, ' ')
        .replace(/<strong>/g, ' ')
        .replace(/<li>/g, '\r\n')
        .replace(/<ul>/g, ' ')
        .replace(/&euro/g, "€")
        .replace(/&EURO/g, "€")
        .replace(/;/g, " ")
        .replace(/<p>/g, " ")
        .replace(/<\/h6 >/g, " ")
        .replace(/&ldquo/g, " ")
        .replace(/&rdquo/g, " ")
        .replace(/<\/strong>/g, ' ')
        .replace(/<p class="x-small-text">/g, " ")
        .replace(/<div class="x-small-text">/g, " ")
        .replace(/<h6>/g, " ")
        .replace(/<\/ul>/g, ' ')
        .replace(/<\/div > /g, " ")
        .replace(p, " ")
        .replace(li, ' ')
        .replace(/&yuml;/g, 'ÿ')
        .replace(patron, '\r\n');



};

function checkPwd(str, idioma) {
    if (str.length < 8) {
        if (idioma == 'ca') {
            return ("La contrasenya és massa curta");
        } else if (idioma == 'in') {
            return ("The password is too short");
        } else {
            return ("La contraseña es demasiado corta");
        }

    } else if (str.search(/\d/) == -1) {
        if (idioma == 'ca') {
            return ("La contrasenya no té numeros");
        } else if (idioma == 'in') {
            return ("The password has no numbers");
        } else {
            return ("La contraseña no tiene numeros");
        }

    } else if (str.search(/[a-z]/) == -1) {
        if (idioma == 'ca') {
            return ("La contrasenya no té lletres");
        } else if (idioma == 'in') {
            return ("The password has no letters");
        } else {
            return ("La contraseña no tiene Letras minúsculas");
        }

    } else if (str.search(/[A-Z]/) == -1) {
        if (idioma == 'ca') {
            return ("La contrasenya no té lletres");
        } else if (idioma == 'in') {
            return ("The password has no letters");
        } else {
            return ("La contraseña no tiene Letras mayúsculas");
        }

    } else if (str.search(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/) == -1) {
        if (idioma == 'ca') {
            return ("La contrasenya no té lletres");
        } else if (idioma == 'in') {
            return ("The password has no letters");
        } else {
            return ("La contraseña no tiene caracteres especiales");
        }

    }
    return ("ok");
};

function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
        "h": hours,
        "m": minutes,
        "s": seconds
    };
    return obj;
};


function secondsToString(seconds) {
    let hour = Math.floor(seconds / 3600);
    hour = (hour < 10) ? '0' + hour : hour;
    let minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10) ? '0' + minute : minute;
    let second = seconds % 60;
    second = (second < 10) ? '0' + second : second;
    return hour + ':' + minute + ':' + second;
};

function mensaTicket(str, id, nombre) {
    if (id === 'undefined') {
        id = '';

    }
    if (nombre === 'undefined') {
        nombre = '';
    }
    const patronId = /{tarifa.id}/g;
    const patronNom = /{tarifa.nombre}/g;
    return String(str).replace(patronId, id)
        .replace(patronNom, nombre);

}

function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

function fechaCorta(fecha) {
    let day = fecha.getDate()
    let month = fecha.getMonth() + 1
    let year = fecha.getFullYear()
    let day2;

    if (month < 10 && day < 10) {
        return `0${day}/0${month}/${year}`;
    } else if (month < 10 && day > 9) {
        return `${day}/0${month}/${year}`;
    } else if (day < 10 && month > 10) {
        return `0${day}/${month}/${year}`;
    } else if (day < 10 && month == 10) {
        return `0${day}/${month}/${year}`;
    } else {
        return `${day}/${month}/${year}`;
    }
}

function sumarMeses(fecha, meses) {
    let day = new Date(fecha);
    console.log(Number(day.getMonth()))
    day.setMonth(Number(day.getMonth()) + Number(meses) - 1);
    return day;
}

function restarMeses(fecha, meses) {
    let day = new Date(fecha);
    console.log(Number(day.getMonth()))
    day.setMonth(Number(day.getMonth()) + Number(meses) - 1);
    return day;
}

function fechaCortaIngles(fecha) {
    let day = fecha.getDate()
    let month = fecha.getMonth() + 1
    let year = fecha.getFullYear()

    if (month < 10 && day < 10) {
        return `${year}/0${month}/0${day}`;
    } else if (month < 10 && day > 9) {
        return `${year}/0${month}/${day}`;
    } else if (day < 10 && month > 10) {
        return `${year}/${month}/0${day}`;
    } else if (day < 10 && month == 10) {
        return `${year}/${month}/0${day}`;
    } else {
        return `${year}/${month}/${day}`;
    }
}

function lopd(str) {
    let lopd = '<input id="lopd" type="checkbox" />';
    let lopdno = '<input id="nolopd" type="checkbox" />';
    let publi = '<input id="publicidad" type="checkbox" />';
    let nopubli = '<input id="nopublicidad" type="checkbox" />';
    let si = 'Acepto';
    let no = 'NO acepto';

    return String(str).replace(lopd, '')
        .replace(lopdno, '')
        .replace(publi, '')
        .replaceAll(si, '')
        .replaceAll(no, '')
        .replace(nopubli, '');
}

function fechaLargaMetodo(cadena) {
    let anio = cadena.substr(0, 4);
    let mes = cadena.substr(5, 2);
    let dia = cadena.substr(8, 2)
    let mescorrecto = parseInt(mes) - 1;
    let nuevafecha = new Date().getTime();
    let dato = new Date(nuevafecha);
    let añadir = new Date(dato.setFullYear(anio, mescorrecto, dia));
    return añadir;
}

function addDaysMetodo(campo) {
    campo.setDate(campo.getDate() + 30);
    return campo;
}
function addDaysGraciaM(campo, dias) {
    if (dias > 0) {
        campo.setDate(campo.getDate() + dias);
    }
    return campo;
}
function intToFloat(int) {
    let float = parseFloat(int).toFixed(2);
    return float;
};

const Util = {
    limpiarHtml(str) {
        return htmlEntities(str);
    },
    verificarpass(str, idioma) {
        return checkPwd(str, idioma);
    },
    segundos(secs) {
        return secondsToTime(secs);
    },
    seconds(seconds) {
        return secondsToString(seconds);
    },
    mensajeTicket(str, id, nombre) {
        return mensaTicket(str, id, nombre);
    },
    strip(html) {
        return stripHtml(html);
    },
    calcularFecha(fecha) {
        return fechaCorta(fecha);
    },
    fechaLarga(fecha) {
        return fechaLargaMetodo(fecha);
    },
    addDays(campo) {
        return addDaysMetodo(campo);
    },
    addDaysGracia(campo, dias) {
        return addDaysGraciaM(campo, dias);
    },
    calcularFechaIngles(fecha) {
        return fechaCortaIngles(fecha);
    },
    sumarMesesFecha(fecha, meses) {
        return sumarMeses(fecha, meses);
    },
    restarMesesFecha(fecha, meses) {
        return restarMeses(fecha, meses)
    },
    aFloat(int) {
        return intToFloat(int);
    },
    limpiarLopd(str) {
        return lopd(str);
    }
};

export default Util;