import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";


import "../../styles/GlobalComponents.css";

class ComponenteinfoTranslated extends React.Component {
    render() {
        const { t } = this.props;
        const info = this.props.datos;
        return (
            <div className="container h-100">
                <div className="lista">
                    <span className="no-coment">{t(`${info}.info`,)}</span>
                </div>
            </div>
        )
    }
}
const Componenteinfo = withTranslation('common')(ComponenteinfoTranslated)
export default Componenteinfo;