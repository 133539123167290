import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import GraficoDonut from '../Home/GraficoDonut';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
let userConsumo1 = [];
let estRo = '';
class ConsumoRealLemonTranslated extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        ddi: '',
        loading: false,
        restante: '0000',
        total: '00',
        icono: 'datos',
        sinDatos: false,
        tarifas: '',
        minutos: 0,
        sms: 0,
        tarifaContratada: '',
        modalBono: false,
        bonos: false
    };

    componentDidMount() {
        const funciones1 = JSON.parse(localStorage.getItem('funciones'));
        const funciones = funciones1[0];
        if (funciones.bonos == 1) {
            this.setState({ bonos: true });
        }
        this.setState({ telefono: this.props.ddi });
        this.ConsumoRealFetch();
    }

    ConsumoRealFetch = async () => {
        userConsumo1 = [];
        try {
            if (this.props.ddi != '') {
                this.setState({ loading: true, error: null });
                try {
                    await api.Clientes.consumoReal(this.props.ddi).then(
                        ({ data }) => (userConsumo1 = data),
                    );
                    if (userConsumo1 != '') {
                        this.setState({ loading: false, error: null, tarifas: userConsumo1 });
                        let tarifas = this.state.tarifas;
                        if (typeof userConsumo1['sms']['consumido'] === undefined) {
                            this.setState({ sms: 0, minutos: userConsumo1['voz']['consumido'].toFixed(0) });
                        } else {
                            this.setState({ sms: userConsumo1['sms']['consumido'], minutos: userConsumo1['voz']['consumido'].toFixed(0) });
                        }
                        this.establecerDatos('datos');
                    } else {
                        this.setState({ loading: false, error: null, sinDatos: true });
                    }
                } catch (error) {

                }
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    establecerDatos = (icono) => {
        let datos;
        let consumido;
        let restante;
        let tarifas = this.state.tarifas;


        if (icono == 'datos') {

            if (typeof tarifas['datos']['contratado'] && tarifas['datos']['contratado'] != undefined && tarifas['datos']['contratado'] != '' && tarifas['datos']['contratado'] != null) {
                datos = tarifas['datos']['contratado'];

            } else {
                datos = tarifas['tarifa']['datos_app'];

            }

            consumido = tarifas['datos']['consumido'];
            let consumo = datos - consumido.toFixed(2);
            restante = consumo.toFixed(2)

            this.setState({ medida: 'GB' });
        } else if (icono == 'sms') {
            if (typeof tarifas['tarifa']['sms'] && tarifas['tarifa']['sms'] != undefined && tarifas['tarifa']['sms'] != '' && tarifas['tarifa']['sms'] != null) {

                datos = tarifas['tarifa']['sms'];
            } else {
                if (typeof tarifas['sms']['contratado'] === undefined) {
                    datos = 0;
                } else {
                    datos = tarifas['sms']['consumido'];
                }
            }


            consumido = tarifas['sms']['consumido'];
            let consumo = datos - consumido;
            restante = consumo;
            this.setState({ medida: 'SMS' });
        } else if (icono == 'llamadas') {
            if (typeof tarifas['voz']['contratado'] && tarifas['voz']['contratado'] != undefined && tarifas['voz']['contratado'] && tarifas['voz']['contratado'] != null) {
                datos = tarifas['voz']['contratado'];
            } else {
                datos = tarifas['tarifa']['minutos_app'];
            }
            consumido = tarifas.voz.consumido;
            if (datos == '-1') {
                restante = 'Ilim.'
                datos = 'Ilim.'
            } else {
                let consumo = datos - consumido;
                restante = consumo.toFixed(2);
            }

            this.setState({ medida: 'MIN' });
        }

        if (restante < 0) {
            restante = 0;
        }
        if (datos == undefined) {
            datos = 0;
        }

        if (restante == undefined) {
            restante = 0
        }

        if ((restante == 0 || restante < 1) && icono == 'datos' && this.state.bonos == true) {
            this.setState({ modalBono: true })
        }


        if (tarifas['tarifa'] != 0) {
            this.setState({ total: datos, restante: restante, tarifaContratada: tarifas['tarifa']['nombre_comercial_app'] });
        } else {
            this.setState({ total: datos, restante: restante, tarifaContratada: '' });
        }


    }

    abrirModalBono = () => {
        this.setState({ modalBono: !this.state.modalBono });
    }

    datos = () => {
        this.establecerDatos('datos');
    }

    llamadas = () => {
        this.establecerDatos('llamadas');

    }

    sms = () => {
        this.establecerDatos('sms');
    }

    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }


        return (
            <div className="consumo-home-global">
                {this.state.tarifaContratada != '' ? <div className='nombretarifa'><span><b>{t('Consumo.contratada',)}</b></span><p>{this.state.tarifaContratada}</p></div> : ''}
                <GraficoDonut
                    restante={this.state.restante}
                    total={this.state.total}
                    medida={this.state.medida}
                    color={'#fcba0c'}
                    iconos={'grey'}
                    responsive={true} />
                <div className="contenedor-iconos" id="sms">
                    <button className="icono-linea-sms" onClick={() => this.sms()} >
                        <FontAwesomeIcon icon="comment-sms" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-llama" onClick={() => this.llamadas()} id="llamadas">
                        <FontAwesomeIcon icon="phone" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-gb" onClick={() => this.datos()} id="datos">
                        <div className={"iconos-consumo-home-dobles"} id="datos">
                            <FontAwesomeIcon icon="arrow-up" className={"iconos-gris"} id='datos' />
                            <FontAwesomeIcon icon="arrow-down" className={"iconos-gris"} id="datos" />
                        </div>
                    </button>

                </div>
                <Modal isOpen={this.state.modalBono} className="roaming-modal">
                    <ModalHeader className="modal-header-roaming">
                        <span style={{
                            fontWeight: 800,
                        }}>{t('Contratacion.quieresBono',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.abrirModalBono}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="modal-body-roaming">
                        <p>{t('Contratacion.info',)}</p>
                    </ModalBody>
                    <ModalFooter className='modal-footer-roaming'>
                        <Link to={{
                            pathname: `/Contratacion/`
                        }} className="btn-bonos-xl">

                            <FontAwesomeIcon icon="cart-shopping" className='icon-white' />
                            <span className='titulo-btn-modal-bono'>{t('Contratacion.contratar',)}</span>
                        </Link>
                    </ModalFooter>
                </Modal>
            </div>
        )


    }
}

const ConsumoRealLemon = withTranslation('common')(ConsumoRealLemonTranslated)
export default ConsumoRealLemon;