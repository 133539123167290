import Axios from "axios";
import Token from "./Token";

const BASE_URL = process.env.REACT_APP_API_PRO
//const BASE_URL = process.env.REACT_APP_API_DEV
let urlZona = '';
async function esperaToken() {
  const llave = await Token.login();
  return llave;
}

async function recuperaZona() {
  const zona = JSON.parse(localStorage.getItem("zona"));
  if (zona != '' && zona != null && zona != undefined) {
    if (zona == 'granada') {
      urlZona = process.env.REACT_APP_API_PRO_CAPITAL;
    } else if (zona == 'almeria') {
      urlZona = BASE_URL;
    } else if (zona == 'sevilla') {
      urlZona = process.env.REACT_APP_API_PRO_SEVILLA;
    }
  } else {
    urlZona = BASE_URL;
  }
  return urlZona;
}

const headers = {
  'Content-Type': 'application/json',
}


async function callApi(endpoint, llamada, options = {}) {
  const token = await esperaToken();
  const apiConjunta = await recuperaZona();
  const url = apiConjunta + endpoint;

  if (llamada === "get") {
    const response = await Axios.get(url, options);
    return response;
  } else {
    llamada.append("token", token);
    const response1 = await Axios.post(url, llamada, {
      headers: headers
    });
    return response1;
  }
}

async function callApisms(endpoint, llamada, options = {}) {
  const token = await esperaToken();
  const apiConjunta = await recuperaZona();
  const url = apiConjunta + endpoint + 'token=' + token + '/';

  if (llamada === "get") {
    const response = await Axios.get(url, options);
    return response;
  } else {
    llamada.append("token", token);
    const response1 = await Axios.post(url, llamada, {
      headers: headers
    });
    return response1;
  }
}

async function callApiIp(endpoint, llamada, options = {}) {

  const url = BASE_URL + endpoint;

  if (llamada === "get") {
    const response = await Axios.get(url, options);
    return response;
  }
}

async function callApiArchivos(endpoint, fd) {
  const token = await esperaToken();
  const apiConjunta = await recuperaZona();
  const url = apiConjunta + endpoint;
  fd.append("token", token);
  const respuesta = await Axios.post(url, fd, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  });
  return respuesta;


}

//let fd = new FormData();
const api = {
  Clientes: {
    listarBonos(operador, ddi) {
      let fd = new FormData();
      fd.append("operador", operador);
      fd.append("ddi", ddi);
      return callApi(`/bonos/`, fd, {
      });
    },
    ip() {
      return callApiIp(`/obtenerip/`, "get", {
        method: "get",
      });
    },
    subirDocu(documento) {
      let fd = new FormData();
      fd.append("file", documento);
      return callApiArchivos(`/subirDocu/`, fd);
    },
    cambiarEstadoOferta(oferta, estado) {
      let fd = new FormData();
      fd.append("oferta", oferta);
      fd.append("estado", estado);
      return callApi(`/cambiarEstadoOferta/`, fd, {
      });
    },
    crearOferta(array) {
      let fd = new FormData();
      fd.append("titulo", array['titulo']);
      fd.append("descripcion", array['descripcion']);
      fd.append("cliente", array['cliente']);
      fd.append("fechainicio", array['fechainicio']);
      fd.append("fechafin", array['fechafin']);
      fd.append("precio", array['precio']);
      fd.append("photo", array['photo']);

      return callApi(`/crearOferta/`, fd, {
      });
    },
    crearCita(array) {
      let fd = new FormData();
      fd.append("titulo", array['titulo']);
      fd.append("descripcion", array['descripcion']);
      fd.append("cliente", array['cliente']);
      fd.append("fecha", array['fecha']);
      fd.append("hora", array['hora']);
      fd.append("tipo", array['tipo']);

      return callApi(`/crearCita/`, fd, {
      });
    },
    modificarTipocliente(cliente, tipoCliente) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("tipoCliente", tipoCliente);
      return callApi(`/modificarTipoCliente/`, fd, {
      });
    },
    getdocumentos(cliente) {
      let fd = new FormData();
      fd.append("cliente", cliente);
      return callApi(`/getGestionDocumental/`, fd, {
      });
    },
    listarCalles() {
      let fd = new FormData();
      return callApi(`/tipoCalle/`, fd, {
      });
    },
    crearCliente(array) {
      let fd = new FormData();
      fd.append("tipoCuenta", array['tipoCuenta']);
      fd.append("apellidos", array['apellidos']);
      fd.append("nombre", array['nombre']);
      fd.append("localidad", array['localidad']);
      fd.append("provincia", array['provincia']);
      fd.append("postal", array['postal']);
      fd.append("dni", array['dni']);
      fd.append("tipoPersona", array['tipoPersona']);
      fd.append("tipoCalle", array['tipoCalle']);
      fd.append("calle", array['calle']);
      fd.append("numero", array['numero']);
      fd.append("movil", array['movil']);
      fd.append("telefono", array['telefono']);
      fd.append("tipoDocumento", array['tipoDocumento']);
      fd.append("email", array['email']);

      return callApi(`/crearCliente/`, fd, {
      });
    },
    clientes() {
      let fd = new FormData();
      return callApi(`/listarClientes/`, fd, {
      });
    },
    listarCitas(cliente) {
      let fd = new FormData();
      fd.append("cliente", cliente);
      return callApi(`/listarCitas/`, fd, {
      });
    },
    listarOfertas(cliente) {
      let fd = new FormData();
      fd.append("cliente", cliente);
      return callApi(`/listarOfertas/`, fd, {
      });
    },
    ofertas() {
      let fd = new FormData();
      return callApi(`/ofertas/`, fd, {
      });
    },
    clientesPotenciales() {
      let fd = new FormData();
      return callApi(`/listarClientesPotenciales/`, fd, {
      });
    },
    buscarCliente(codigo) {
      let fd = new FormData();
      fd.append("codigo", codigo);
      return callApi(`/buscarCliente/`, fd, {
      });
    },
    consumoActualXtra(recurso) {
      let fd = new FormData();
      fd.append("ddi", recurso);
      return callApi(`/consumoActualXtra/`, fd, {
      });
    },
    facturas(recurso, recurso1) {
      let fd = new FormData();
      fd.append("idFiscal", recurso);
      fd.append("year", recurso1);
      return callApi(`/facturas/`, fd, {
      });
    },
    activarRoaming(recurso, recurso1) {
      let fd = new FormData();
      fd.append("ddi", recurso);
      fd.append("accion", recurso1);
      return callApi(`/activarRoaming/`, fd, {
      });
    },
    consultarRoaming(recurso) {
      let fd = new FormData();
      fd.append("ddi", recurso);
      return callApi(`/estadoRoaming/`, fd, {
      });
    },
    consultarServicios(recurso) {
      let fd = new FormData();
      fd.append("ddi", recurso);
      return callApi(`/verServicios/`, fd, {
      });
    },
    modificarServicios(recurso) {
      let fd = new FormData();
      fd.append("servicios", recurso);
      return callApi(`/guardarServicios/`, fd, {
      });
    },
    obtenerNumFactura(recurso) {
      let fd = new FormData();
      fd.append("factura", recurso);
      return callApi(`/obtenerNumFactura/`, fd, {

      });
    },
    consumoReal(recurso) {
      let fd = new FormData();
      fd.append("ddi", recurso);
      return callApi(`/consumoReal/`, fd, {

      });
    },
    saberApiTipo(recurso) {
      let fd = new FormData();
      fd.append("ddi", recurso);
      return callApi(`/apiTipo/`, fd, {

      });
    },
    consultarDescuentosDetalle(recurso, recurso1) {
      let fd = new FormData();
      fd.append("cliente", recurso);
      fd.append("idDes", recurso1);
      return callApi(`/consultarDescuentosDetalle/`, fd, {
      });
    },
    consultarDescuentos(recurso) {
      let fd = new FormData();
      fd.append("cliente", recurso);
      return callApi(`/consultarDescuentos/`, fd, {

      });
    },
    consultarPromocion(recurso) {
      let fd = new FormData();
      fd.append("cliente", recurso);
      return callApi(`/consultarPromociones/`, fd, {

      });
    },
    descargaFacturas(recurso) {
      return callApisms(`/facturas/downloads/${recurso}/`, "get", {
        responseType: 'blob',

      });
    },
    descargaFacturasAndroid(recurso, recurso2) {
      let fd = new FormData();
      fd.append("idfactura", recurso);
      fd.append("cliente", recurso2);
      return callApi(`/facturas/crear/`, fd, {

      });
    },
    datosEmpresa() {
      let fd = new FormData();
      return callApi(`/datos_empresa/`, fd, {
      });
    },
    registrarApp(recurso) {
      let fd = new FormData();
      fd.append("id", recurso);
      return callApi(`/registrarApp/`, fd, {
      });
    },
    configApp() {
      let fd = new FormData();
      return callApi(`/configuracion_app_empresa/`, fd, {
      });
    },
    tarifasMoviles() {
      let fd = new FormData();
      return callApi(`/tarifas_moviles/`, fd, {
      });
    },
    tarifasCompuestas() {
      let fd = new FormData();
      return callApi(`/tarifas_compuestas/`, fd, {
      });
    },
    tarifasFibra() {
      let fd = new FormData();
      return callApi(`/tarifas_fibra/`, fd, {
      });
    },
    tarifasTv() {
      let fd = new FormData();
      return callApi(`/tarifas_tv/`, fd, {
      });
    },
    tarifasfijas() {
      let fd = new FormData();
      return callApi(`/tarifas_fijas/`, fd, {
      });
    },
    tarifasRed() {
      let fd = new FormData();
      return callApi(`/tarifas_red/`, fd, {
      });
    },
    tarifasInternet() {
      let fd = new FormData();
      return callApi(`/tarifas_internet/`, fd, {
      });
    },
    tokenPaypal() {
      let fd = new FormData();
      return callApi(`/tokenpaypal/`, fd, {
      });
    },
    tokenTarjeta() {
      let fd = new FormData();
      return callApi(`/tokentarjeta/`, fd, {
      });
    },
    tokenRedsys(factura) {
      let fd = new FormData();
      fd.append("factura", factura)
      return callApi(`/tokenredsys/`, fd, {
      });
    },
    tokenBizum(factura) {
      let fd = new FormData();
      fd.append("factura", factura)
      return callApi(`/tokenbizum/`, fd, {
      });
    },
    restoFactura(recurso) {
      let fd = new FormData();
      fd.append("factura", recurso)
      return callApi(`/restoFactura/`, fd, {
      });
    },
    contratos(recurso) {
      let fd = new FormData();
      fd.append("idFiscal", recurso)
      return callApi(`/contratos_app/`, fd, {
      });
    },
    contratosRecarga(recurso) {
      let fd = new FormData();
      fd.append("cliente", recurso)
      return callApi(`/contratosRecargas/`, fd, {
      });
    },
    telefonos(recurso) {
      let fd = new FormData();
      fd.append("id", recurso);
      return callApi(`/ddi_cliente/`, fd, {
      });
    },
    puk(recurso) {
      let fd = new FormData();
      fd.append("ddi", recurso);
      return callApi(`/get_puk/`, fd, {
      });
    },
    consultaCdr(telefono, mes, anio, tipo) {
      let fd = new FormData();
      fd.append("num", telefono);
      fd.append("mes", mes);
      fd.append("anio", anio);
      fd.append("tipo", tipo);
      return callApi(`/resumen_cdr/`, fd, {
      });
    },
    consultaCdrDetalle(telefono, mes, anio, tipo) {
      let fd = new FormData();
      fd.append("num", telefono);
      fd.append("mes", mes);
      fd.append("anio", anio);
      fd.append("tipo", tipo);
      return callApi(`/cdr/`, fd, {
      });
    },
    documentosFirma(recurso) {
      let fd = new FormData();
      fd.append("cliente", recurso);
      return callApi(`/get_documentos_firmar/`, fd, {
      });
    },
    tickets(recurso, recurso1) {
      let fd = new FormData();
      fd.append("idFiscal", recurso);
      fd.append("resuelto", recurso1);
      return callApi(`/tickets/`, fd, {
      });
    },
    ticketsTodos(recurso) {
      let fd = new FormData();
      fd.append("idFiscal", recurso);
      return callApi(`/ticketsAll/`, fd, {
      });
    },
    asuntoTicket() {
      let fd = new FormData();
      return callApi(`/asunto_tickets/`, fd, {
      });
    },
    diasGracia(recurso) {
      let fd = new FormData();
      fd.append("contrato", recurso);
      return callApi(`/diasGracia/`, fd, {
      });
    },
    departamentos() {
      let fd = new FormData();
      return callApi(`/departamentos/`, fd, {
      });
    },
    pasarelas_sms() {
      let fd = new FormData();
      return callApi(`/pasarelas_sms/`, fd, {
      });
    },
    noticias() {
      let fd = new FormData();
      return callApi(`/noticias/`, fd, {
      });
    },
    noticiaDestacada() {
      let fd = new FormData();
      return callApi(`/noticiaDestacada/`, fd, {
      });
    },
    noticiaHome() {
      let fd = new FormData();
      return callApi(`/noticiaHome/`, fd, {
      });
    },
    recuperarPassEmail(recurso) {
      let fd = new FormData();
      fd.append("idFiscal", recurso);
      return callApi(`/recordarpasswporemail/`, fd, {
      });
    },
    comentariosTicket(recurso) {
      let fd = new FormData();
      fd.append("idticket", recurso);
      return callApi(`/comentarios_ticket/`, fd, {
      });
    },
    nuevoTicket(cliente, departamento, asunto, descripcion, mostrar) {
      let fd = new FormData();
      fd.append("cliente", cliente);
      fd.append("codasunto", asunto);
      fd.append("departamento", departamento);
      fd.append("asuntoamplia", descripcion);
      fd.append("mostrar_panel_cliente", mostrar);
      return callApi(`/create/nuevo_tickets/`, fd, {
      });
    },
    nuevoComentario(recurso, recurso1) {
      let fd = new FormData();
      fd.append("idticket", recurso);
      fd.append("texto", recurso1);
      fd.append("mostrar_cliente", 1);
      return callApi(`/create/nuevo_comentario_ticket/`, fd, {
      });
    },
    stripeCliente(recurso, recurso1) {
      let fd = new FormData()
      fd.append("factura", recurso1);
      fd.append("importe", recurso);
      return callApi(`/stripeCliente/`, fd, {
      });
    },
    serviciosActivos(recurso) {
      let fd = new FormData()
      fd.append("idcliente", recurso);
      return callApi(`/servicios_activos/`, fd, {
      });
    },
    consumoActual(recurso, recurso1, recurso2) {
      let fd = new FormData()
      fd.append("tlf", recurso);
      fd.append("fechaInicio", recurso1);
      fd.append("fechaFin", recurso2);
      return callApi(`/consumo_actual/p`, fd, {
      });
    },
    calcularPrecio(recurso, recurso1, recurso2, recurso3, recurso4, recurso5, recurso6) {
      let fd = new FormData()
      fd.append("tarifa", recurso);
      fd.append("fechainicio", recurso1);
      fd.append("fechafin", recurso2);
      fd.append("cliente", recurso3);
      fd.append("contrato", recurso4);
      fd.append("checkmes", recurso5);
      fd.append("meses", recurso6);
      return callApi(`/calcularPrecio/`, fd, {
      });
    },
    preparaPago(recurso, recurso1, recurso2) {
      let fd = new FormData()
      fd.append("factura", recurso);
      fd.append("importe", recurso1);
      fd.append("cliente", recurso2)
      return callApi(`/preparaPago/`, fd, {
      });
    },
    preparaPagoBono(tarifa, importe, cliente, ddi) {
      let fd = new FormData()
      fd.append("tarifa", tarifa);
      fd.append("importe", importe);
      fd.append("cliente", cliente);
      fd.append("ddi", ddi)
      return callApi(`/preparaPagoBono/`, fd, {
      });
    },
    dispositivoAsociado(recurso) {
      let fd = new FormData()
      fd.append("contratoid", recurso)
      return callApi(`/dispositivoClienteAsociado/`, fd, {
      });
    },
    preparaPagoRecargas(recurso, recurso1, recurso2) {
      let fd = new FormData()
      fd.append("contrato", recurso);
      fd.append("importe", recurso1);
      fd.append("cliente", recurso2)
      return callApi(`/prepararPagoRecargas/`, fd, {
      });
    },
    capturaPago(recurso, recurso1, recurso2) {
      let fd = new FormData()
      fd.append("idCobro", recurso);
      fd.append("respuesta", recurso1);
      fd.append("pasarela", recurso2)
      return callApisms(`/capturaPago/`, fd, {
      });
    },
    capturaPagoRecargas(recurso, recurso1, recurso2, recurso3, recurso4, recurso5, recurso6) {
      let fd = new FormData()
      fd.append("idCobro", recurso);
      fd.append("respuesta", recurso1);
      fd.append("pasarela", recurso2);
      fd.append("fechainicio", recurso3);
      fd.append("fechafin", recurso4);
      fd.append("meses", recurso5);
      fd.append("check", recurso6);
      return callApi(`/capturaPagoRecargas/`, fd, {
      });
    },
    envioPago(recurso, recurso1, recurso3) {
      let fd = new FormData()
      fd.append("factura", recurso);
      fd.append("importe", recurso1);
      fd.append("bizum", recurso3);
      return callApi(`/firmaRes/`, fd, {
      });
    },
    modificarContraseña(cliente, pass) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("pass", pass);
      return callApi(`/update/clientes/`, fd, {
      });
    },
    modificarUsuario(cliente, pass) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("pass", pass);
      return callApi(`/update/usuarios/`, fd, {
      });
    },
    obtenerAgenda(usuario, cliente) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("usuario", usuario);
      return callApi(`/obtenerAgendas/`, fd, {
      });
    },
    obtenerContactos(usuario, cliente) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("usuario", usuario);
      return callApi(`/obtenerAgendasContactos/`, fd, {
      });
    },
    obtenerAgendaClientes(cliente) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      return callApi(`/obtenerAgendasClientes/`, fd, {
      });
    },
    obtenerContactosContactos(cliente) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      return callApi(`/obtenerAgendasContactosClientes/`, fd, {
      });
    },
    permisosUsuarios(cliente) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      return callApi(`/permisosUsuarios/`, fd, {
      });
    },
    envioCliente(cliente, pass) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("pass", pass);
      return callApi(`/envioCliente/`, fd, {
      });
    },
    envioToken(cliente, token, dispositivo) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("tokenRegistro", token);
      fd.append("dispositivo", dispositivo);
      return callApi(`/registroToken/`, fd, {
      });
    },
    guardarLopd(publi, lopd) {
      let fd = new FormData()
      fd.append("publi", publi);
      fd.append("lopd", lopd);
      return callApi(`/guardarLopd/`, fd, {
      });
    },
    envioSMS(cliente, pasarela, remitente, texto, telefono) {
      let fd = new FormData()
      fd.append("cliente", cliente);
      fd.append("pasarela", pasarela);
      fd.append("remitente", remitente);
      fd.append("texto", texto);
      fd.append("telefono", telefono);
      return callApisms(`/enviar/`, fd, {
      });
    },
    nuevaImagen(recurso, recurso1) {
      let fd = new FormData();
      fd.append('file', recurso1);
      fd.append('id_tic', recurso)
      return callApiArchivos(`/create/nuevo_adjunto/`, fd);
    },
  },
};

export default api;
