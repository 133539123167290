import React from 'react';
import { IonAlert } from '@ionic/react';

const Alert = (props) => {
    return (
        <>
            <IonAlert
                isOpen={props.estado}
                cssClass={props.clase}
                header={props.header}
                message={`<img src="${props.imagen}" alt="error" className="errorimg">`}
                buttons={[{
                    text: [`${props.text}`],
                    handler: () => {
                        window.location = props.accion;
                    }
                }]}
            />
        </>
    )
}
export default Alert;