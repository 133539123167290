import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import {
    ticketOutline, barChartOutline, newspaperOutline, serverOutline, readerOutline,
    powerOutline, receiptOutline, bookOutline, personCircleOutline
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

class MenuHomeBtnTranslated extends React.Component {
    state = {
        misDatos: true,
        consumo: true,
        contratos: true,
        recargas: true,
        facturas: true,
        tarifas: true,
        documentos: true,
        noticias: true,
        incidencias: true,
        loading: true,
    };

    componentDidMount() {
        const funciones1 = JSON.parse(localStorage.getItem('funciones'));
        const funciones = funciones1[0];

        if (funciones.misDatos == 0) {
            this.setState({ misDatos: false });
        }

        if (funciones.consumo == 0) {
            this.setState({ consumo: false });
        }

        if (funciones.contratos == 0) {
            this.setState({ contratos: false });
        }

        if (funciones.recargas == 0) {
            this.setState({ recargas: false });
        }
        if (funciones.facturas == 0) {
            this.setState({ facturas: false });
        }

        if (funciones.tarifas == 0) {
            this.setState({ tarifas: false });
        }

        if (funciones.documentos == 0) {
            this.setState({ documentos: false });
        }

        if (funciones.noticias == 0) {
            this.setState({ noticias: false });
        }

        if (funciones.incidencias == 0) {
            this.setState({ incidencias: false });
        }

        if (localStorage.getItem('permisos') !== undefined && localStorage.getItem('permisos') !== '' && localStorage.getItem('permisos') != null) {
            const permisosApp = JSON.parse(localStorage.getItem('permisos'));
            if (!permisosApp.includes(1)) {
                this.setState({ noticias: false });
            }
            if (!permisosApp.includes(2)) {
                this.setState({ facturas: false });
            }
            if (!permisosApp.includes(3)) {
                this.setState({ consumo: false });
            }
            if (!permisosApp.includes(4)) {
                this.setState({ incidencias: false });
            }
            if (!permisosApp.includes(5)) {
                this.setState({ contratos: false });
            }
            if (!permisosApp.includes(6)) {
                this.setState({ documentos: false });
            }
        }

        this.setState({ loading: false });
    }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.state.misDatos && <div className="div-home">
                    <Link to='/Datos' className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={personCircleOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.datos',)}</p>
                    </Link>
                </div>}
                {this.state.consumo && <div className="div-home">
                    <Link to="/Consumo" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={barChartOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.consumo',)}</p>
                    </Link>
                </div>}
                {this.state.contratos && <div className="div-home">
                    <Link to="/Contratos" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={readerOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.contratos',)}</p>
                    </Link>
                </div>}
                {this.state.facturas && <div className="div-home">
                    <Link to="/Facturas" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={newspaperOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.facturas',)}</p>
                    </Link>
                </div>}
                {this.state.tarifas && <div className="div-home">
                    <Link to="/Tarifas" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={serverOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.tarifas',)}</p>
                    </Link>
                </div>}
                {this.state.documentos && <div className="div-home">
                    <Link to="/Documento" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={receiptOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.documentos',)}</p>
                    </Link>
                </div>}
                {this.state.noticias && <div className="div-home">
                    <Link to="/Noticias" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={bookOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.noticias',)}</p>
                    </Link>
                </div>}
                {this.state.incidencias && <div className="div-home">
                    <Link to="/Tickets" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos" icon={ticketOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.tickets',)}</p>
                    </Link>
                </div>}
                <div className="div-home-cerrar">
                    <Link to="/Login" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos icon-red-home" icon={powerOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.cerrar',)} </p>
                    </Link>
                </div>
            </React.Fragment>
        )
    }
};

const MenuHomeBtn = withTranslation('common')(MenuHomeBtnTranslated);
export default MenuHomeBtn;